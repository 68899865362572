.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  min-height: 100vh;
  & > div {
    display: flex;
    width: 600px;
  }
}
