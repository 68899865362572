.content {
  display: flex;
  padding: 96px 32px 0;
  justify-content: center;
  flex: 1;
  min-height: 100vh;
  height: 100vh;
}

.innerContent {
  width: 100%;
  padding-left: 256px;
  &_collapsed {
    padding-left: 80px;
  }
}
