@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/Gilroy-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
