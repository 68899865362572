.header {
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 0 8px 24px rgba(166, 171, 189, 0.2);
  & > span {
    color: wheat;
  }
  justify-content: space-between;
}
